// ==================================================
//  モバイル判定イベント
// ==================================================
function is_mobile() {
  const intFrameWidth = window.innerWidth;
  return intFrameWidth <= 767 ? true : false;
}

// ==================================================
//  ヘッダーメニューボタン（SP）
// ==================================================
const menu = document.querySelector(".js-headerMenu");
const menuActiveClass = "js-headerMenu-active";
const menuButton = document.querySelector(".js-headerMenuButton");
const menuBUttonActiveClass = "js-headerMenuButton-active";
function menuToggle() {
  menu.classList.toggle(menuActiveClass);
  menuButton.classList.toggle(menuBUttonActiveClass);
}
menuButton.addEventListener("click", (event) => {
  menuToggle();
});

// ==================================================
//  ヘッダーメニュー内のドロップダウン
// ==================================================
document.addEventListener("DOMContentLoaded", () => {
  const toggles = document.querySelectorAll(".js-headerMenuListItemDropdown");
  toggles.forEach((toggle) => {
    toggle.addEventListener("click", () => {
      toggle.classList.toggle("js-headerMenuListItemDropdown-active");
    });
  });
});

// ==================================================
//  FAQ
//  よくあるご質問（サポート情報）のトグル開閉
// ==================================================
document.addEventListener("DOMContentLoaded", () => {
  const isPageSupport = document.querySelector("main").classList.contains("page-support");
  if (isPageSupport) {
    const toggles = document.querySelectorAll(".js-faqToggleButton");
    toggles.forEach((toggle) => {
      toggle.addEventListener("click", () => {
        toggle.parentNode.classList.toggle("js-faqToggle-active");
      });
    });
  }
});

// ==================================================
//  Apps
//  機能の子ページでh2の数に合わせてアンカーリンクを作成
// ==================================================
document.addEventListener("DOMContentLoaded", () => {
  const isPageApps = document.querySelector("main").classList.contains("page-apps");
  if (isPageApps) {
    const appsH2 = document.querySelectorAll(".wpContentApps h2");
    if (appsH2 && appsH2.length) {
      let appsH2Anchor = "";
      appsH2.forEach((appsH2Item, i) => {
        appsH2Anchor += '<a href="#function-' + i + '" class="appsLink_item">' + appsH2Item.textContent + "</a>";
        appsH2Item.setAttribute("id", "function-" + i);
      });
      document.querySelector(".appsLink_inner").innerHTML += appsH2Anchor;
    }
  }
});

// ==================================================
//  トップページ
//  追従バナーの表示・非表示
// ==================================================
document.addEventListener("DOMContentLoaded", () => {
  const isPageTop = document.querySelector("main").classList.contains("page-top");
  if (isPageTop) {
    const fixedBanner = document.querySelector(".js-fixedBanner");
    const fixedBannerCloseButton = document.querySelector(".js-fixedBannerCloseButton");
    setTimeout(() => {
      fixedBanner.classList.add("js-fixedBanner-show");
    }, 1.0 * 500);

    fixedBannerCloseButton.addEventListener("click", () => {
      fixedBanner.classList.remove("js-fixedBanner-show");
    });
  }
});

// ==================================================
//  スクロールアニメーション
// ==================================================
const fadeInClass = "js-scrollFadeIn";
const fadeInActiveClass = "js-scrollFadeIn-active";
const callback = (entries) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      entry.target.classList.add(fadeInActiveClass);
    }
  });
};
const options = {
  root: null,
  rootMargin: "89% 0px -11% 0px",
  threshold: 0,
};
let observer = new IntersectionObserver(callback, options);
const animationItems = document.querySelectorAll("." + fadeInClass);
animationItems.forEach((item) => {
  observer.observe(item);
});

// ==================================================
//  アンカーのスムーススクロール
// ==================================================
document.addEventListener("DOMContentLoaded", () => {
  const anchors = document.querySelectorAll('a[href^="#"]');
  const headerHeight = document.querySelector("header").offsetHeight;
  const urlHash = location.hash;
  for (let i = 0; i < anchors.length; i++) {
    anchors[i].addEventListener("click", (e) => {
      e.preventDefault();
      const href = anchors[i].getAttribute("href");
      if (href !== "#top" && href !== "#") {
        const target = document.getElementById(href.replace("#", ""));
        const position = window.pageYOffset + target.getBoundingClientRect().top - headerHeight;

        window.scroll({
          top: position,
          behavior: "smooth",
        });
      } else {
        window.scroll({
          top: 0,
          behavior: "smooth",
        });
      }
    });
  }
});

// ==================================================
//  追従ヘッダーの横スクロール対応
// ==================================================
const header = document.querySelector(".header");
let scrollTicking = false;
document.addEventListener(
  "scroll",
  (event) => {
    if (!scrollTicking && header !== null) {
      requestAnimationFrame(() => {
        scrollTicking = false;
        header.style.left = -window.scrollX + "px";
      });
      scrollTicking = true;
    }
  },
  { passive: true }
);
